import React from "react";
import PropTypes from "prop-types";

const Component = ({ glyph }) => {
  switch (glyph) {
    case "caret-left":
      return (
        <g id="caret-left">
          <path
            d="M9.7,16.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
          c-0.1-0.5,0.1-1,0.4-1.4c0.1-0.1,0.2-0.2,0.4-0.3l9.3-8.5c0,0,0,0,0,0c0.7-0.6,1.8-0.5,2.4,0.1s0.5,1.8-0.1,2.4
          c-2.7,2.5-5.4,5-8.2,7.4c2.7,2.5,5.4,5,8.1,7.4c0.7,0.6,0.8,1.7,0.1,2.4c-0.6,0.7-1.7,0.8-2.4,0.1c0,0,0,0,0,0l-9.3-8.5
          c-0.1-0.1-0.2-0.2-0.3-0.3C9.9,16.9,9.7,16.7,9.7,16.4z"
          />
        </g>
      );
    case "caret-up":
      return (
        <g id="caret-up">
          <path
            d="M15.7,9.8c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
            c0.5-0.1,1,0.1,1.4,0.4c0.1,0.1,0.2,0.2,0.3,0.4l8.5,9.3c0,0,0,0,0,0c0.6,0.7,0.5,1.8-0.1,2.4c-0.7,0.6-1.8,0.5-2.4-0.1
            c-2.5-2.7-5-5.4-7.4-8.2c-2.5,2.7-5,5.4-7.4,8.1c-0.6,0.7-1.7,0.8-2.4,0.1c-0.7-0.6-0.8-1.7-0.1-2.4c0,0,0,0,0,0l8.5-9.3
            c0.1-0.1,0.2-0.2,0.3-0.3C15.2,9.9,15.4,9.8,15.7,9.8z"
          />
        </g>
      );
    case "cc-logo":
      return (
        <g id="cc-logo">
          <circle
            id="background_circle"
            strokeMiterlimit="10"
            cx="15.9"
            cy="15.9"
            r="15.2"
          />
          <g id="me">
            <path
              id="hair"
              strokeMiterlimit="10"
              d="M26.5,26.5V12.8c0-5.5-4.5-9.9-9.9-9.9
            s-9.9,4.5-9.9,9.9v13.5c0,0.7-0.1,1.2,0,1.6"
            />
            <path
              id="sleeve_left"
              strokeMiterlimit="10"
              d="M13.4,26.7L13.4,26.7c0.1-1.4-0.8-2.7-2.1-3
            c-1.3-0.3-2.7,0.5-3.2,1.7l-1,2.9c0,0,1.9,1.1,2.2,1.1"
            />
            <path
              id="shirt"
              strokeMiterlimit="10"
              d="M25.4,27.9c-1.3-3.3-4.4-5.5-8-5.5
            c-4.2,0-7.7,2.9-8.4,7.1c0,0,5.8,3.2,11.4,0.9"
            />
            <path
              id="sleeve_right"
              strokeMiterlimit="10"
              d="M26.2,26.9c-0.2-1.3-1.3-2.4-2.8-2.4
            c-1.4,0-2.7,1.1-2.8,2.5v0.1l-0.4,3.3C20.3,30.3,25.1,28.4,26.2,26.9"
            />
            <path
              id="neck_shadow"
              display="none"
              strokeMiterlimit="10"
              d="M20,23.6
            c0.1,1.1-1.3,2.1-3.2,2.3c-1.8,0.2-3.3-0.7-3.4-1.8c-0.1-1.1,1.4-1.1,3.3-1.3C18.5,22.6,19.9,22.5,20,23.6z"
            />
            <ellipse
              id="neck_1"
              strokeMiterlimit="10"
              cx="16.7"
              cy="22.5"
              rx="2.4"
              ry="1.6"
            />
            <path
              id="face_1"
              strokeMiterlimit="10"
              d="M15.8,22.7L15.8,22.7c-3.9,0-7.1-3.3-7.1-7.1v-3.9
            c0-3.9,3.3-7.1,7.1-7.1l0,0c3.9,0,7.1,3.3,7.1,7.1v3.9C22.9,19.5,19.6,22.7,15.8,22.7z"
            />
            <path
              id="bangs"
              strokeMiterlimit="10"
              d="M22.6,11.1c0,0.4-0.1,0.4-0.5,0.4c-1.8,0-3.7,0-5.5,0
            c-1.2,0-2.6,0-3.8,0c-1.3,0-2.7,0-4.1,0c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4c0-3.4,3.2-6,6.6-6.4
            c2-0.2,4.2,0.4,5.7,1.6C21.9,7.4,22.8,9.2,22.6,11.1z"
            />
            <ellipse
              id="eye_right"
              strokeMiterlimit="10"
              cx="18.3"
              cy="14.3"
              rx="0.4"
              ry="0.9"
            />
            <ellipse
              id="eye_left"
              strokeMiterlimit="10"
              cx="11.2"
              cy="14.3"
              rx="0.4"
              ry="0.9"
            />
          </g>
          <g id="bow">
            <path
              strokeMiterlimit="10"
              d="M14.6,4.3l-1.7,0.6c-0.5-0.1-0.9-0.6-0.8-1.1l0.3-1.4
            c0.1-0.5,0.6-0.9,1.1-0.8l1.3,1.2c0.5,0.1,0.9,0.6,0.8,1.1l0.1-0.3C15.6,4.1,15.1,4.5,14.6,4.3z"
            />
            <path
              strokeMiterlimit="10"
              d="M16.1,4.7l1.3,1.2c0.5,0.1,1.1-0.2,1.1-0.8l0.3-1.4
            c0.1-0.5-0.3-1-0.8-1.1l-1.7,0.7c-0.5-0.1-1.1,0.2-1.1,0.8l0.1-0.5C15.2,4,15.5,4.6,16.1,4.7z"
            />
            <path
              strokeMiterlimit="10"
              d="M15.5,4.8L15,4.7c-0.4-0.1-0.6-0.4-0.5-0.8l0.1-0.7
            c0.1-0.4,0.4-0.6,0.8-0.5l0.5,0.1c0.4,0.1,0.6,0.4,0.5,0.8l-0.1,0.7C16.2,4.7,15.8,4.9,15.5,4.8z"
            />
          </g>
        </g>
      );
    case "circle":
      return (
        <g id="circle">
          <circle cx="16.1" cy="16.3" r="13.7" />
        </g>
      );
    case "close":
      return (
        <g>
          <path d="M8.867,6.49L25.5,23.116a1.68,1.68,0,1,1-2.376,2.375L6.491,8.865A1.68,1.68,0,1,1,8.867,6.49Z" />
          <path d="M23.123,6.49L6.491,23.116a1.68,1.68,0,1,0,2.376,2.375L25.5,8.865A1.68,1.68,0,1,0,23.123,6.49Z" />
        </g>
      );
    case "github":
      return (
        <g>
          <path
            d="M16.3,2C8,2,1.3,8.7,1.3,17
          c0,6.6,4.3,12.2,10.2,14.2c0.7,0.1,1-0.3,1-0.7c0-0.4,0-1.3,0-2.5c-4.2,0.9-5-2-5-2c-0.7-1.7-1.7-2.2-1.7-2.2
          c-1.4-0.9,0.1-0.9,0.1-0.9c1.5,0.1,2.3,1.5,2.3,1.5c1.3,2.3,3.5,1.6,4.4,1.2c0.1-1,0.5-1.6,1-2c-3.3-0.4-6.8-1.7-6.8-7.4
          c0-1.6,0.6-3,1.5-4c-0.2-0.4-0.7-1.9,0.1-4c0,0,1.3-0.4,4.1,1.5c1.2-0.3,2.5-0.5,3.7-0.5c1.3,0,2.6,0.2,3.7,0.5
          c2.9-1.9,4.1-1.5,4.1-1.5c0.8,2.1,0.3,3.6,0.1,4c1,1,1.5,2.4,1.5,4c0,5.8-3.5,7-6.8,7.4c0.5,0.5,1,1.4,1,2.8c0,2,0,3.6,0,4.1
          c0,0.4,0.3,0.9,1,0.7c5.9-2,10.2-7.6,10.2-14.2C31.2,8.7,24.5,2,16.3,2z"
          />
        </g>
      );
    case "linkedin":
      return (
        <g id="linkedin">
          <path
            d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M11.4,24.2H7.5V12.5h3.9V24.2z
			 M9.4,10.9L9.4,10.9c-1.3,0-2.2-0.9-2.2-2c0-1.2,0.9-2,2.2-2s2.2,0.9,2.2,2C11.6,10,10.8,10.9,9.4,10.9z M25.4,24.2h-3.9v-6.3
			c0-1.6-0.6-2.7-2-2.7c-1.1,0-1.7,0.7-2,1.4c-0.1,0.3-0.1,0.6-0.1,1v6.5h-3.9c0,0,0.1-10.6,0-11.7h3.9v1.7c0.5-0.8,1.4-1.9,3.5-1.9
			c2.6,0,4.5,1.7,4.5,5.3V24.2z"
          />
        </g>
      );
    case "medium":
      return (
        <g id="medium">
          <path
            d="M16,1C7.7,1,1,7.7,1,16s6.7,15,15,15s15-6.7,15-15S24.3,1,16,1z M22.6,12.4h-0.2
          c0,0-0.5,0-0.5,0.5v6.2c0,0,0,0.5,0.5,0.5h0.2v1.5h-4.4v-1.5h0.7v-6.8h-0.1l-2.2,8.2h-2l-2.2-8.2h-0.1v6.8h0.7v1.5H9.4v-1.5h0.2
          c0,0,0.5,0,0.5-0.5v-6.2c0,0,0-0.5-0.5-0.5H9.4v-1.5h4.8l1.7,6.3H16l1.7-6.3h4.8V12.4z"
          />
        </g>
      );
    case "cc-spinner":
      return (
        <g id="cc-spinner" display="inline">
          <path id="background_circle" strokeMiterlimit={10} d="M14.9,31.4" />
          <g id="me">
            <path
              id="hair"
              strokeMiterlimit={10}
              strokeLinecap="round"
              d="M26,26.6V12.3c0-5.7-4.7-10.4-10.4-10.4S5.2,6.6,5.2,12.3v14.1c0,0.7-0.1,1.3,0,1.7l0.6,0.4"
            />
            <path
              id="sleeve_left"
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12.3,26.8L12.3,26.8c0.1-1.5-0.8-2.8-2.2-3.1c-1.4-0.3-2.8,0.5-3.3,1.8l-1,3
          c0,0,2,1.1,2.3,1.1"
            />
            <path
              id="shirt_2_"
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M24.5,27.4c-1.5-3.1-4.6-5.2-8.1-5.2c-4.4,0-8,3-8.8,7.4c0,0,6.1,3.3,11.9,0.9"
            />
            <path
              id="sleeve_right"
              strokeMiterlimit={10}
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M25.7,27c-0.2-1.4-1.4-2.5-2.9-2.5s-2.8,1.1-2.9,2.6v0.1l-0.4,3.4
          C19.5,30.6,24.5,28.6,25.7,27"
            />
            <path
              id="neck_shadow"
              stroke="none"
              fill="#D6D6D6"
              d="M19.2,23.6c0.1,1.2-1.4,2.2-3.3,2.4s-3.5-0.7-3.6-1.9c-0.1-1.2,1.5-1.2,3.4-1.4
          S19.1,22.4,19.2,23.6z"
            />
            <ellipse
              id="neck"
              strokeMiterlimit={10}
              cx="15.7"
              cy="22.4"
              rx="2.5"
              ry="1.7"
            />
            <path
              id="face"
              strokeMiterlimit={10}
              d="M14.8,22.6L14.8,22.6c-4.1,0-7.4-3.4-7.4-7.4v-4.1c0-4.1,3.4-7.4,7.4-7.4l0,0
          c4.1,0,7.4,3.4,7.4,7.4v4.1C22.2,19.3,18.8,22.6,14.8,22.6z"
            />
            <path
              id="bangs"
              strokeMiterlimit={10}
              d="M21.9,10.5c0,0.4-0.1,0.4-0.5,0.4c-1.9,0-3.9,0-5.8,0c-1.3,0-2.7,0-4,0c-1.4,0-2.8,0-4.3,0
          c-0.1,0-0.2,0-0.3-0.1s-0.1-0.2-0.1-0.4c0-3.6,3.3-6.3,6.9-6.7c2.1-0.2,4.4,0.4,6,1.7C21.2,6.6,22.1,8.5,21.9,10.5z"
            />
          </g>
          <g id="bow" display="inline">
            <path
              strokeMiterlimit={10}
              d="M13.5,3.4L11.7,4c-0.5-0.1-0.9-0.6-0.8-1.2l0.3-1.5c0.1-0.5,0.6-0.9,1.2-0.8l1.4,1.3c0.5,0.1,0.9,0.6,0.8,1.2
        l0.1-0.3C14.6,3.2,14.1,3.6,13.5,3.4z"
            />
            <path
              strokeMiterlimit={10}
              d="M15.1,3.8l1.4,1.3c0.5,0.1,1.1-0.2,1.2-0.8L18,2.8c0.1-0.5-0.3-1-0.8-1.2l-1.8,0.7c-0.5-0.1-1.1,0.2-1.2,0.8
        l0.1-0.5C14.2,3.1,14.5,3.7,15.1,3.8z"
            />
            <path
              strokeMiterlimit={10}
              d="M14.5,3.9L14,3.8c-0.4-0.1-0.6-0.4-0.5-0.8l0.1-0.7c0.1-0.4,0.4-0.6,0.8-0.5l0.5,0.1c0.4,0.1,0.6,0.4,0.5,0.8
        l-0.1,0.7C15.2,3.8,14.8,4,14.5,3.9z"
            />
          </g>
          <g display="inline" transform="translate(-2 0)">
            <g transform="translate(1 0)">
              <path
                id="spiral_left"
                d="
		M11.3,14.6c0.2,0.1,0.5,0.1,0.7-0.2c0.1-0.2,0.1-0.5-0.2-0.7c-0.5-0.3-1.1-0.2-1.4,0.3s-0.2,1.1,0.3,1.4c0.7,0.4,1.6,0.2,2.1-0.5
		s0.2-1.6-0.5-2.1c-0.9-0.6-2.2-0.3-2.7,0.6c-0.6,0.9-0.3,2.2,0.6,2.7c1.2,0.7,2.7,0.4,3.4-0.8c0.7-1.2,0.4-2.7-0.8-3.4
		c-1.4-0.9-3.2-0.5-4.1,0.9s-0.5,3.2,0.9,4.1"
              ></path>
            </g>

            <g transform="translate(0 0)">
              <path
                id="spiral_right"
                d="
		M20.5,14.6c0.3,0.1,0.5-0.1,0.6-0.3c0.1-0.3-0.1-0.5-0.3-0.6c-0.5-0.2-1.1,0.1-1.3,0.6c-0.2,0.5,0.1,1.1,0.6,1.3
		c0.8,0.3,1.6-0.2,1.9-0.9c0.3-0.8-0.2-1.6-0.9-1.9c-1-0.3-2.2,0.2-2.5,1.3s0.2,2.2,1.3,2.5c1.3,0.4,2.7-0.3,3.1-1.6
		s-0.3-2.7-1.6-3.1c-1.6-0.5-3.3,0.3-3.8,1.9s0.3,3.3,1.9,3.8"
              />
            </g>
          </g>
        </g>
      );
    default:
      return (
        <g>
          <rect width="32" height="32" />
          <path
            fillRule="evenodd"
            d="M34.354-1.646l-0.707-.707-36,36,0.707,0.707Z"
          />
          <path
            fillRule="evenodd"
            d="M-2.354-1.646l0.707-.707,36,36-0.707.707Z"
          />
        </g>
      );
  }
};

Component.propTypes = {
  glyph: PropTypes.string.isRequired,
};

Component.displayName = "Glyph";
export default Component;
